const VehicleInfoModal = ({ isOpen, onClose, vehicle }) => {
  if (!isOpen) return null; // Don't render the modal if it's not open
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded shadow-md w-full max-w-md relative">
        {/* Close "X" Button */}
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 text-2xl"
        >
          &times; {/* X symbol */}
        </button>
        {/* Modal Content */}
        <div className="mb-4 flex flex-col">
        <div className="w-20 h-20 rounded-full border-4 border-white">
          <img
            src={vehicle.mainPhoto || 'https://via.placeholder.com/80'} // Use vehicle.photoURL or a placeholder if not available
            className="rounded-full object-cover w-full h-full"
            alt="Vehicle Photo"
          />
        </div>
          <div>{vehicle.year} {vehicle.make} {vehicle.model}</div>
          <div className="text-sm mt-4"><strong>Color: </strong>{vehicle.color}</div>
          <div className="text-sm mt-4"><strong>License Plate: </strong>{vehicle.plate}</div>
          <div className="text-sm mt-4"><strong>Amenities:</strong></div>
          <div className="text-sm grid grid-cols-2 gap-2">
            {vehicle.amenities.map((amenity, index) => (
              <div key={index} className="mt-2">&middot; {amenity}</div>
            ))}
          </div>
          <div className="text-sm mt-4"><strong>Rules:</strong></div>
          <div className="text-sm">
            {vehicle.rules.map((rule, index) => (
              <div key={index} className="text-sm mt-2">&middot; {rule}</div>
            ))}
          </div>
          <div className="text-sm mt-4"><strong>Rates:</strong></div>
          <div className="text-sm">{vehicle.rates}</div>

        </div>
      </div>
    </div>
  );
};
export default VehicleInfoModal;
