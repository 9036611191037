import { useState } from "react";
const AddMenuItemModal = ({ isOpen, onClose, children, finished }) => {
  if (!isOpen) return null; // Don't render the modal if it's not open
  return (
    finished ? (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-6 rounded shadow-md w-full max-w-md relative">
          {/* Close "X" Button */}
          <button
            onClick={onClose}
            className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 text-2xl"
          >
            &times; {/* X symbol */}
          </button>
          {/* Modal Content */}
          <div className="mb-8 text-center text-blue-500 font-semibold">Vehicle Added</div>
          <button
						onClick={onClose}
						className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
						Close
					</button>
        </div>
      </div>
    ) : (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-6 rounded shadow-md w-full max-w-md relative">
          {/* Close "X" Button */}
          <button
            onClick={onClose}
            className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 text-2xl"
          >
            &times; {/* X symbol */}
          </button>
          {/* Modal Content */}
          <div className="mb-4">{children}</div>
        </div>
      </div>
    )
  );
};
export default AddMenuItemModal;
