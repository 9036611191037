import React, { useState, useEffect } from 'react';
import { getFirestore, collection, onSnapshot, query, where, orderBy, doc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const InboxComponent = ({ selectConversation }) => {
  const [conversations, setConversations] = useState([]);
  const [loading, setLoading] = useState(true);
  const firestore = getFirestore();
  const auth = getAuth();

  useEffect(() => {
    // Ensure user is authenticated
    const user = auth.currentUser;
    if (!user) {
      console.error('User is not authenticated');
      return;
    }

    const userId = user.uid;

    // Reference to the conversations collection
    const conversationsRef = collection(firestore, 'conversations');

    // Query to fetch only the conversations where the authenticated user is a participant
    const q = query(conversationsRef, where('participants', 'array-contains', userId), orderBy('updatedAt', 'desc'));

    // Set up real-time listener for conversations
    const unsubscribe = onSnapshot(q, async (snapshot) => {
      const fetchedConversations = [];

      for (const docSnapshot of snapshot.docs) {
        const conversationData = docSnapshot.data();
        const otherParticipantId = conversationData.participants.find((id) => id !== userId);

        // Fetch the username of the other participant
        const usernameDoc = await getDoc(doc(firestore, 'profiles', otherParticipantId));
        const otherParticipantName = usernameDoc.exists() ? usernameDoc.data().username : 'Unknown';

        fetchedConversations.push({
          id: docSnapshot.id,
          participantName: otherParticipantName,
          ...conversationData,
        });
      }

      setConversations(fetchedConversations);
      setLoading(false);
    }, (error) => {
      console.error('Error fetching conversations:', error);
      setLoading(false);
    });

    // Clean up listener on component unmount
    return () => unsubscribe();
  }, [firestore, auth]);

  return (
    <div className="p-4 h-full">
      <div className="text-lg font-semibold mb-4">Inbox</div>
      <div className="space-y-4 overflow-y-auto">
        {loading ? (
          <div>Loading...</div>
        ) : (
          conversations.length > 0 ? (
            conversations.map((conversation) => (
              <div
                key={conversation.id}
                className="p-4 border border-gray-300 rounded-md hover:bg-gray-100 cursor-pointer"
                onClick={() => selectConversation(conversation.id)}
              >
                <div className="font-medium">{conversation.participantName}</div>
                <div className="text-sm text-gray-600">{conversation.latestMessage}</div>
                <div className="text-xs text-gray-400">{new Date(conversation.updatedAt).toLocaleString()}</div>
              </div>
            ))
          ) : (
            <div>No conversations available</div>
          )
        )}
      </div>
    </div>
  );
};

export default InboxComponent;
