import { useId } from 'react'
import clsx from 'clsx'
const formClasses =
  'block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm'
function Label({ id, children }) {
  return (
    <label
      htmlFor={id}
      className="mb-3 block text-sm font-medium text-gray-700"
    >
      {children}
    </label>
  )
}
export function TextField({ label, type = 'text', className, icon, ...props }) {
  let id = useId();
  return (
    <div className={clsx('relative', className)}>
      {label && <Label id={id}>{label}</Label>}
      {icon && (
        <span className="absolute top-1/2 left-0 transform  pl-3 text-gray-500 text-xl">
        {icon}
      </span>
      )}
      <input id={id} type={type} {...props} className={clsx(formClasses, icon && 'pl-8')} />
    </div>
  );
}
export function SelectField({ label, className, ...props }) {
  let id = useId()
  return (
    <div className={className}>
      {label && <Label id={id}>{label}</Label>}
      <select id={id} {...props} className={clsx(formClasses, 'pr-8')} />
    </div>
  )
}
export function TextAreaField({ label, className, rows = 4, ...props }) {
  let id = useId();
  return (
    <div className={className}>
      {label && <Label id={id}>{label}</Label>}
      <textarea
        id={id}
        rows={rows}
        {...props}
        className={clsx(formClasses, 'resize-none')}
      />
    </div>
  );
}