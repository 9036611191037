import React, { useState, useEffect } from 'react';
import { functions } from '../../firebase.js';
import { httpsCallable } from 'firebase/functions';

const SubStatus = () => {
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Use the httpsCallable function correctly
  const getSubStatus = httpsCallable(functions, 'getSubStatus');

  useEffect(() => {
    const fetchSubStatus = async () => {
      try {
        const result = await getSubStatus({ fart: 'stinks' });
        setStatus(result.data.message);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchSubStatus();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <h2>Subscription Status</h2>
      <p>{status ? `Status: ${status}` : 'No status available'}</p>
    </div>
  );
};

export default SubStatus;
