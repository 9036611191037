import React, { useState } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { toast } from 'react-toastify'; // Optional: for user notifications
const SendMessageModal = ({ recipientId, onClose }) => {
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!message.trim()) {
      toast.error('Message cannot be empty.');
      return;
    }
    setLoading(true);
    const functions = getFunctions();
    const sendMessage = httpsCallable(functions, 'sendMessage');
    try {
      await sendMessage({ 
        recipientId, 
        messageText: message 
      });
      setMessage('');
      toast.success('Message sent successfully!');
      onClose(); // Close modal after sending message
    } catch (error) {
      console.error('Error sending message:', error);
      toast.error('Failed to send message.');
    }
    setLoading(false);
  };
  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
          <button
            onClick={onClose}
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-800"
          >
            &times;
          </button>
          <h2 className="text-lg font-semibold mb-4">Send a Message</h2>
          <form onSubmit={handleSendMessage} className="flex flex-col">
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type your message here..."
              className="border border-gray-300 p-2 rounded-lg mb-2"
              rows="4"
            />
            <button
              type="submit"
              className={`px-4 py-2 rounded-lg bg-blue-500 text-white ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
              disabled={loading}
            >
              {loading ? 'Sending...' : 'Send'}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};
export default SendMessageModal;
