import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from './AuthContext';
import SpinnerComponent from '../components/SpinnerComponent';
const ProtectedRoute = () => {
  const { currentUser, authLoading } = useAuth(); // Get the current user from AuthContext
  // Show a loading spinner while the authentication status is loading
  if (authLoading) {
    return (
      <SpinnerComponent />
    ); 
  }
  // If there is no current user, redirect to the login page
  if (!currentUser) {
    return <Navigate to="/login" replace />;
  }
  // If the user is logged in, render the children (protected content)
  return <Outlet />;
};
export default ProtectedRoute;




