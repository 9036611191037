import React, { createContext, useContext, useEffect, useState } from "react";
import { auth, db } from "../firebase"; // Import Firebase Auth and Firestore
import { onAuthStateChanged, signInWithEmailAndPassword, signOut, createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore"; // Import Firestore methods
import { getFireErrorMessage } from "../utils/FireErrorMessages";
const AuthContext = createContext();
export const useAuth = () => useContext(AuthContext);
export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [authLoading, setAuthLoading] = useState(true);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setAuthLoading(false);
    });
    return () => unsubscribe();
  }, []);
  const createProfile = async (user) => {
    const userId = user.uid;
    const profileRef = doc(db, "profiles", userId);
    try {
      await setDoc(profileRef, { email: user.email, userId: userId, profile_active: false });
      return true;
    } catch (error) {
      const friendlyMessage = getFireErrorMessage(error);
      throw new Error(friendlyMessage);
    }
  };
  const register = async (email, password) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      // Return the userCredential instead of true
      return userCredential;
    } catch (error) {
      const friendlyMessage = getFireErrorMessage(error);
      throw new Error(friendlyMessage);
    }
  };
  const login = async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      console.log('error: ' + error);
      console.log('error.code: ' + error.code);
      const friendlyMessage = getFireErrorMessage(error);
      throw new Error(friendlyMessage); // Return a user-friendly message
    }
  };
  const logout = () => {
    return signOut(auth);
  };
  const value = { currentUser, login, logout, register, authLoading }; // Export register function
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
