import React from 'react';
import { Link } from 'react-router-dom';

const RegisterSuccessPage = () => {
  return (
    <>
      <h2 className="mt-20 text-lg font-semibold text-gray-900">
        Registration Successful!
      </h2>
      <p className="mt-2 text-sm text-gray-700">
        Your account has been successfully created. You can now log in to your new account.
      </p>
      <div className="mt-4">
        <Link
          to="/login"
          className="inline-block px-4 py-2 text-sm font-semibold text-white bg-blue-600 rounded hover:bg-blue-500 focus:outline-none focus-visible:outline-2 focus-visible:outline-blue-600"
        >
          Go to Login
        </Link>
      </div>
      </>
  );
};

export default RegisterSuccessPage;
