import { useNavigate } from 'react-router-dom';

const MyVehicleListItem = ({ vehicle }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/edit-vehicle', { state: { vehicle: vehicle } });
  };

  return (
    <div className="grid grid-cols-1 gap-4" onClick={handleClick}>
      <div className="w-full border border-gray-300 rounded p-2 flex items-center cursor-pointer">
        {vehicle.mainphoto && (
          <img
            src={vehicle.mainphoto}
            alt={`${vehicle.make} ${vehicle.model}`}
            className="w-12 h-12 rounded-full mr-4"
          />
        )}
        <div>
          {vehicle.year} {vehicle.make} {vehicle.model}
        </div>
      </div>
    </div>
  );
};

export default MyVehicleListItem;
