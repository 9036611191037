import SubStatus from "../../components/subscription/SubStatus";

const AccountPage = () => {
  return (
    <>
    <div>ACCOUNT PAGE</div>
    <SubStatus />
    </>
  );
};

export default AccountPage;
