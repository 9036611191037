import { useEffect, useState } from "react";
import AddVehicleModal from "../../modals/AddVehicleModal";
import MyVehicleListItem from "../../components/MyVehicleListItem.js";
import { collection, query, where, getDocs } from 'firebase/firestore';
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { functions } from "../../firebase.js";
import { httpsCallable } from "firebase/functions";
const MyVehiclesPage = () => {
  const navigate = useNavigate();
	const [formData, setFormData] = useState({});
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
  const [finished, setFinished] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
  const [vehicles, setVehicles] = useState([]);
	const openModal = () => {
    setFinished(false); 
    setIsModalOpen(true);
  }
	const closeModal = () => {
    setFormData({});
    setError(null);
    setIsModalOpen(false);
  };
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};
	const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      const addVehicle = httpsCallable(functions, "addVehicle");
      const result = await addVehicle({formData});
      if (result.data.success) {
        setFinished(true);
      } else {
        setError(result.data.message);
      }
    } catch (err) {
      setError("An unexpected error occurred.");
    } finally {
      setLoading(false);				
    }
	};
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const userId = user.uid; // Get the current authenticated user's ID
        const fetchVehicles = async () => {
          const q = query(collection(db, 'vehicles'), where('user_id', '==', userId));
          const querySnapshot = await getDocs(q);
          const items = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
          setVehicles(items);
          setLoading(false);
        };
        fetchVehicles();
      } else {
        // User is signed out
        navigate('/login');
      }
    });
    return () => unsubscribe(); // Clean up the listener on unmount
  }, []);
  if (loading) {
    return <div>Loading...</div>;
  }
	return (
		<div className="flex flex-col items-center justify-center">
			<div className="flex justify-between items-center w-full max-w-[1200px] px-4 py-6">
				<h1>My Vehicles</h1>
				<button
					className="bg-blue-500 text-white px-4 py-2 rounded-md mt-1"
					onClick={openModal}>
					Add Vehicle
				</button>
			</div>
			<div className="w-full bg-white p-4 shadow-md rounded-lg mx-auto">
      <div className="grid grid-cols-1 gap-4">
        {vehicles.map((vehicle) => (
          <MyVehicleListItem key={vehicle.id} vehicle={vehicle} />
        ))}
      </div>
			</div>
			<AddVehicleModal isOpen={isModalOpen} onClose={closeModal} finished={finished}>
				<div className="w-full border-b mb-4">Add Vehicle</div>
				<form>
					{/* Year, Make, Model, Color, License Plate, Amenities, Rules, Rates, Photos */}
					<div className="mb-4">
						<label
							htmlFor="year"
							className="block text-gray-700 font-bold mb-2 text-sm">
							<span className="text-red-500">*</span>Year
						</label>
						<select
							id="year"
							name="year"
							onChange={handleChange}
							required
							className="block w-full px-3 py-2 text-gray-700 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500">
							<option value="" disabled selected>
								Select One...
							</option>
							{Array.from({ length: 76 }, (v, k) => 2025 - k).map((year) => (
								<option key={year} value={year}>
									{" "}
									{year}{" "}
								</option>
							))}
						</select>
					</div>
					<div className="mb-4">
						<label
							htmlFor="make"
							className="block text-gray-700 font-bold mb-2 text-sm">
							<span className="text-red-500">*</span>Make
						</label>
						<input
							type="text"
							id="make"
							name="make"
							required
							onChange={handleChange}
							className="block w-full px-3 py-2 text-gray-700 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
						/>
					</div>
					<div className="mb-4">
						<label
							htmlFor="model"
							className="block text-gray-700 font-bold mb-2 text-sm">
							<span className="text-red-500">*</span>Model
						</label>
						<input
							type="text"
							id="model"
							name="model"
							required
							onChange={handleChange}
							className="block w-full px-3 py-2 text-gray-700 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
						/>
					</div>
					{error && <p className="text-red-500">{error}</p>}
					<button
						type="submit"
						disabled={loading}
						onClick={handleSubmit}
						className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
						<span> {loading ? 'Adding...' : 'Add Vehicle'} </span>
					</button>
				</form>
			</AddVehicleModal>
		</div>
	);
};
export default MyVehiclesPage;
