import { useState, useEffect } from "react";
import SpinnerComponent from "../../components/SpinnerComponent";
import { functions } from "../../firebase.js";
import { httpsCallable } from "firebase/functions";
const EditPaymentLinksPage = () => {
  const [loading, setLoading] = useState(false);
  const [links, setLinks] = useState([]);
  const [serviceName, setServiceName] = useState("");
  const [serviceLink, setServiceLink] = useState("");
  useEffect(() => {
    const fetchPaymentLinks = async () => {
      setLoading(true);
      try {
        const getPaymentLinks = httpsCallable(functions, "getMyPaymentLinks");
        const result = await getPaymentLinks();
        if (result.data.success) {
          setLinks(result.data.links);
        }
      } catch (err) {
        console.error(err); // Moved into the catch block to handle errors properly
      } finally {
        setLoading(false); // Ensures loading is set to false whether success or error
      }
    };
    fetchPaymentLinks();
  }, []);
  const handleRemoveLink = async (index) => {
    setLoading(true);
    try {
      const { serviceName, serviceLink } = links[index]; // Get the service name and link from the selected index
      const removePaymentLink = httpsCallable(functions, "removePaymentLink");
      const result = await removePaymentLink({ serviceName, serviceLink });
      if (result.data.success) {
        alert(result.data.message);
        setLinks((prevLinks) => prevLinks.filter((_, i) => i !== index)); // Update the state to remove the link
      } else {
        //alert(result.data.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  if(loading){
    return <SpinnerComponent />
  }
  const handleAddPaymentLink = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const addPaymentLink = httpsCallable(functions, "addPaymentLink");
      const result = await addPaymentLink({serviceName, serviceLink});
      if (result.data.success) {
        alert(result.data.message);
      } else {
        //alert(result.data.message);
      }
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };
  return (
    <div className="mainBox">
      <div className="whiteBox">
        <div className="text-md font-semibold mb-4 border-b pb-2">Payment Links</div>
        <div className="flex flex-wrap">
            {links.map((link, index) => (
              <div key={index} className="w-full flex flex-col items-center mb-2 mr-2 bg-gray-200 px-3 py-1 rounded-sm">
                <div className="flex justify-between w-full">
                <span className="font-semibold">{link.serviceName}</span>
                <button
                  onClick={() => handleRemoveLink(index)}
                  className="ml-2 text-red-500 font-bold">
                  X
                </button>
                </div>
                <div className="w-full p-2">{link.serviceLink}</div>
              </div>
            ))}
          </div>
      </div>
      <div className="whiteBox mt-6">
        <div className="text-md font-semibold mb-4 border-b pb-2">Add Payment Link</div>
        <form>
        <div className="mt-2">
          <label htmlFor="serviceName" className="text-sm">Service Name</label>
          <input type="text" id="serviceName" name="serviceName" value={serviceName || ''} onChange={(e) => setServiceName(e.target.value)}  className="block w-full p-2 border border-gray-300 rounded mt-1"  />
        </div>
        <div className="mt-2">
          <label htmlFor="serviceLink" className="text-sm">Service Link</label>
          <input type="text" id="serviceLink" name="serviceLink" value={serviceLink || ''} onChange={(e) => setServiceLink(e.target.value)} className="block w-full p-2 border border-gray-300 rounded mt-1" />
        </div>
        <button
          type="submit"
          className={`w-full mt-6 py-2 px-4 mb-4 rounded text-white ${loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600'}`}
          disabled={loading} // Disable button if loading or uploading
          onClick={handleAddPaymentLink}
        >
        {loading ? 'Saving...' : 'Add Payment Link'}
      </button>
      </form>
      </div>
    </div>
  );
};
export default EditPaymentLinksPage;