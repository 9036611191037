import { useState, useEffect } from "react";
import { getAuth } from "firebase/auth"; // Import Firebase Auth module
import { getFirestore, doc, getDoc } from "firebase/firestore"; 
import { QRCodeCanvas } from "qrcode.react";
import SpinnerComponent from "../../components/SpinnerComponent";
import Announcements from "../../components/Announcements";

const DashboardPage = () => {
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(true);
  const [profileUrl, setProfileUrl] = useState("");
  const auth = getAuth();
  const firestore = getFirestore(); 
  useEffect(() => {
    const fetchUsername = async () => {
      const currentUser = auth.currentUser;
      if (currentUser) {
        const userId = currentUser.uid; // Get the authenticated user's UID
        const profileRef = doc(firestore, "profiles", userId); // Reference to the user's profile document
        try {
          const profileDoc = await getDoc(profileRef);
          if (profileDoc.exists()) {
            setUsername(profileDoc.data().username); // Set the username from the Firestore document
            setProfileUrl("https://RideaFide.com/driver/" + username);
          } else {
            console.log("No profile found for this user.");
          }
        } catch (error) {
          console.error("Error fetching username:", error);
        }
      } else {
        console.log("No user is logged in.");
      }
      setLoading(false);
    };
    fetchUsername();
  }, [auth, firestore]);

  if (loading) {
    return <SpinnerComponent />;
  }
  return (
    <div className="mainBox">
      <div className="whiteBox">
        <div className="text-md font-semibold mb-4 border-b pb-2">Dashboard for {username}</div>
        <div className="w-full flex flex-col items-center justify-center mb-4">
          <div className="mb-2">My QR Code</div>
          <QRCodeCanvas 
              value={profileUrl} 
              size={150} 
              bgColor={"#ffffff"}
              fgColor={"#000000"}
              title={"YOLO"}
              level={"H"} 
            />
            <div className="mt-4 mb-2">My Profile Link</div>
            <div className="mt-2 mb-2">
              <p className="text-sm">{profileUrl}</p>
            </div>
        </div>
      </div>
      <div className="whiteBox mt-6">
      <div className="text-md font-semibold mb-4 border-b pb-2">Announcements</div>
      <div>
        <Announcements />
      </div>
      </div>
  </div>
  );
}

export default DashboardPage;