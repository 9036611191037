import { useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { TextField } from '../components/Fields';
import { Button } from '../components/Button';
import ReCAPTCHA from 'react-google-recaptcha';
import { getFunctions, httpsCallable } from "firebase/functions"; 
// RegisterPage component
const RegisterPage = () => {
  // State variables for form fields
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [username, setUsername] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null); 
  const navigate = useNavigate();
  const { register, currentUser, authLoading } = useAuth();
  const functions = getFunctions();
  const finalizeRegister = httpsCallable(functions, 'finalizeRegister');
  const checkUsername = httpsCallable(functions, 'checkUsername');
  const validateCaptcha = httpsCallable(functions, 'validateCaptcha');
  const recaptcha = useRef();
  const handleRegister = async (e) => {
    e.preventDefault();
    setError(null);
    // Validate password match
    if (password !== passwordConfirm) {
      setError("Passwords do not match");
      return;
    }
    setLoading(true);
    try {
      const checkUsernameResult = await checkUsername({ username });
      if (checkUsernameResult.data.exists) {
        setError("Username is already taken.");
        setLoading(false);
        return;
      }
      const recaptchaValue = recaptcha.current.getValue();
    if (!recaptchaValue) {
      setError("Please complete the reCAPTCHA");
      setLoading(false);
      return;
    }
      // Call the validateCaptcha cloud function
    const captchaResult = await validateCaptcha({ token: recaptchaValue });
    if (!captchaResult.data.success) {
      setError("Invalid reCAPTCHA");
      setLoading(false);
      return;
    }
      // Add registration logic to include new fields
      const result = await register(email, password);
      if (result) {
        // Call the finalizeRegister cloud function
        await finalizeRegister({ 
          uid: result.user.uid, // Pass the user's UID
          username, 
          firstName, 
          lastName, 
          dateOfBirth 
        });
        
        navigate("/register-success");
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  if (authLoading) {
    return <p>Loading...</p>;
  }
  if (currentUser) {
    navigate("/dashboard");
  }
  return (
    <div className="flex flex-col items-center justify-center py-6">
      <h1 className="text-2xl font-bold mb-6">Register Account</h1>
      <div className='w-full max-w-sm bg-white p-8 shadow-md rounded-lg'>
        <form onSubmit={handleRegister} className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2">
          <TextField className="col-span-full" label="Username" name="username" onChange={(e) => setUsername(e.target.value)} type="text" autoComplete="username" required />
          <TextField className="col-span-full" label="First Name" name="firstName" onChange={(e) => setFirstName(e.target.value)} type="text" autoComplete="given-name" required />
          <TextField className="col-span-full" label="Last Name" name="lastName" onChange={(e) => setLastName(e.target.value)} type="text" autoComplete="family-name" required />
          <TextField className="col-span-full" label="Date of Birth" name="dateOfBirth" onChange={(e) => setDateOfBirth(e.target.value)} type="date" autoComplete="bday" required />
          <TextField className="col-span-full" label="Email address" name="email" onChange={(e) => setEmail(e.target.value)} type="email" autoComplete="email" required />
          <TextField className="col-span-full" label="Password" name="password" onChange={(e) => setPassword(e.target.value)} type="password" autoComplete="new-password" required />
          <TextField className="col-span-full" label="Confirm Password" name="passwordConfirm" onChange={(e) => setPasswordConfirm(e.target.value)} type="password" autoComplete="new-password" required />
          <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} ref={recaptcha} />
          <div className="col-span-full">
            {error && <p className="text-red-500 text-sm">{error}</p>}
          </div>
          <div className="col-span-full">
            <Button type="submit" variant="solid" color="blue" className="w-full" disabled={loading}>
              <span>
                {loading ? "Registering..." : "Sign up"} <span aria-hidden="true">&rarr;</span>
              </span>
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default RegisterPage;
