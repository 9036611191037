import React from 'react';
import HeaderComponent from './HeaderComponent';

const Layout = ({ children }) => {
  return (
    <div className="flex flex-col items-center min-h-screen bg-gray-100">
      {/* Header */}
      <HeaderComponent />

      {/* Main Content Wrapper */}
      <main className="w-full max-w-[1200px] px-4 py-6">
        {children}
      </main>
    </div>
  );
};

export default Layout;
