import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import VehicleInfoModal from '../modals/VehicleInfoModal';
import SendMessageModal from '../modals/SendMessageModal';
const DriverProfile = () => {
  const { username } = useParams();
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [recipientId, setRecipientId] = useState(null);
  const handleOpenSendMessageModal = () => {
    setIsMessageModalOpen(true);
  }
  const handleCloseMessageModal = () => {
    setIsMessageModalOpen(false);
  }
  const handleOpenModal = (vehicle) => {
    setSelectedVehicle(vehicle);
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedVehicle(null);
  };
  useEffect(() => {
    const functions = getFunctions();
    const getDriverProfile = httpsCallable(functions, 'getDriverProfile');
    getDriverProfile({ username })
      .then((result) => {
        setProfile(result.data);
        setRecipientId(result.data.id);
        setLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }, [username]);
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  return (
    <div className="mainBox">     
        {profile ? (
          <>
          {/* TopBox */}
           <div className="whiteBox">
           <div className="text-md font-semibold mb-4 border-b pb-2">{profile.username}</div>
           <div className="flex flex-col items-center w-full">
            {/* First row: Picture and Name */}
            <div className="flex items-center w-full mb-4">
              <div className="w-24 h-24 rounded-full border-4 border-white">
                <img
                  className="rounded-full object-cover w-full h-full"
                  src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80"
                  alt="Driver Avatar"
                />
              </div>
              <div className="ml-4">
                <div className="text-sm">{profile.firstName}</div>
              </div>
            </div>
            {/* Second row: Centered Send Message Button */}
            <div className="w-full flex justify-center">
              <button
                onClick={() => handleOpenSendMessageModal}
                className="px-4 py-2 bg-blue-500 text-white text-sm font-semibold rounded hover:bg-blue-600"
              >
                Send Message
              </button>
            </div>
          </div>
          </div>
          {/* About Driver */}
          <div className="whiteBox mt-2">
            <div className="text-md font-semibold mb-4 border-b pb-2">About Driver</div>
            <div className="text-sm"><strong>Bio: </strong></div>
            <div className="text-sm">{profile.bio}</div>
            <div className="text-sm mt-4"><strong>Languages Spoken: </strong></div>
            <div>
              {profile.languages.map((language, index) => (
                <div key={index} className="text-sm mt-2">&middot; {language}</div>
              ))}
            </div>
            <div className="text-sm mt-4"><strong>Availability: </strong></div>
            <div className="text-sm">{profile.availability}</div>
            <div className="text-sm mt-4"><strong>Payment Links: </strong></div>
            <div className="text-sm">
            {profile.paymentLinks?.map((paymentLink, index) => (
              <div key={index} className="text-sm mt-2">&middot; {paymentLink}</div>
            )) || <div className="text-sm mt-2">No payment links available.</div>}
            </div>
          </div>
          {/* Vehicles */}
          <div className="whiteBox mt-2">
            <div className="text-md font-semibold mb-4 border-b pb-2">Vehicles</div>
            <div className="text-sm">
            {profile.vehicles.map((vehicle, index) => (
              <div key={index} className="flex items-center rounded-md border-2 border-gray-300 p-2 mb-2" onClick={() => handleOpenModal(vehicle)}>
                <div className="w-20 h-20 rounded-full border-4 border-white">
                  <img
                    src={vehicle.mainPhoto || 'https://via.placeholder.com/80'} // Use vehicle.photoURL or a placeholder if not available
                    className="rounded-full object-cover w-full h-full"
                    alt="Vehicle Photo"
                  />
                </div>
                <div className="ml-4">
                  <div className="text-md items-center flex">{vehicle.year} {vehicle.make} {vehicle.model}</div>
                </div>
              </div>
            ))}
            </div>
          </div>
          </>
        ) : (
          <div className="text-sm mt-4">No profile found for {username}</div>
        )}
        {/* Vehicle Info Modal */}
      <VehicleInfoModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        vehicle={selectedVehicle}
      />
      <SendMessageModal
              recipientId={recipientId}
              isOpen={isMessageModalOpen}
              onClose={handleCloseMessageModal}
            />
      </div>
  );
};
export default DriverProfile;
