const AddMenuItemModal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null; // Don't render the modal if it's not open

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded shadow-md w-full max-w-md relative">
        {/* Close "X" Button */}
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 text-2xl"
        >
          &times; {/* X symbol */}
        </button>
        
        {/* Modal Content */}
        <div className="mb-4">{children}</div>
      </div>
    </div>
  );
};

export default AddMenuItemModal;
