import { useState } from 'react';
import { functions } from "../../firebase.js";
import { httpsCallable } from "firebase/functions";
const EditUserInfo = ({ handleChange, formData, loading, uploading, resultMessage = null, setLoading, setFormData }) => {
  const [newLanguage, setNewLanguage] = useState("");
  const handleAddLanguage = async () => {
    setLoading(true);
    if (newLanguage.trim()) {
      try {
        const addSpokenLanguage = httpsCallable(functions, "addSpokenLanguage");
        const result = await addSpokenLanguage({ language: newLanguage });
        if (result.data.success) {
          setFormData((prevData) => ({
            ...prevData,
            languages: [...(prevData.languages || []), newLanguage],
          }));
        }
        setNewLanguage("");
      } catch (err) {
        console.error(err);
        // setResultMessage("An unexpected error occurred.");
      } finally {
        setLoading(false);
      }
    }
  };
  const handleRemoveLanguage = async (index) => {
    setLoading(true);
    const languageToRemove = formData.languages[index]; // Get the language to remove
    try {
      const removeSpokenLanguage = httpsCallable(functions, "removeSpokenLanguage");
      const result = await removeSpokenLanguage({ language: languageToRemove });
      if (result.data.success) {
        setFormData((prevData) => ({
          ...prevData,
          languages: prevData.languages.filter((_, i) => i !== index), // Remove language from local state
        }));
      }
    } catch (err) {
      console.error(err);
      // setResultMessage("An unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
    <div className='w-full max-w-sm bg-white p-4 shadow-md rounded-lg mx-auto mt-4'>
      <div className="text-lg font-bold mb-6 flex border-b">Personal Info</div>
      <div className='mt-0'>
        <label htmlFor="username" className="text-sm">Username</label>
        <input type="text" id="username" name="username" value={formData.username || ''} onChange={handleChange} className="block w-full p-2 border border-gray-300 rounded mt-1" disabled={true} />
      </div>
      <div className='mt-2'>
        <label htmlFor="firstName" className="text-sm">First Name</label>
        <input type="text" id="firstName" name="firstName" value={formData.firstName || ''} onChange={handleChange} className="block w-full p-2 border border-gray-300 rounded mt-1" disabled={true} />
      </div>
      <div className="mt-2">
        <label htmlFor="lastName" className="text-sm">Last Name</label>
        <input type="text" id="lastName" name="lastName" value={formData.lastName || ''} onChange={handleChange} className="block w-full p-2 border border-gray-300 rounded mt-1" disabled={true} />
      </div>
      <div className='mt-2'>
        <label htmlFor="dateOfBirth" className="block text-gray-700 text-sm">Date of Birth:</label>
        <input type="date" id="dateOfBirth" name="dateOfBirth" value={formData.dateOfBirth || ''} onChange={handleChange} className="block w-full p-2 border border-gray-300 rounded mt-1" disabled={true} />
      </div>
    </div>
    <div className='w-full max-w-sm bg-white p-4 shadow-md rounded-lg mx-auto mt-4'>
      <div className="text-lg font-bold mb-6 flex border-b">Contact Info</div>
      <div className="mt-2">
        <label htmlFor="email" className="text-sm">Email</label>
        <input type="email" id="email" name="email" value={formData.email || ''} onChange={handleChange} className="block w-full p-2 border border-gray-300 rounded mt-1" required />
      </div>
      <div className="mt-2">
        <label htmlFor="phoneNumber" className="text-sm">Phone Number</label>
        <input type="tel" id="phoneNumber" name="phoneNumber" value={formData.phoneNumber || ''} onChange={handleChange} className="block w-full p-2 border border-gray-300 rounded mt-1" required />
      </div>
    </div>
    <div className='w-full max-w-sm bg-white p-4 shadow-md rounded-lg mx-auto mt-4'>
      <div className="text-lg font-bold mb-6 flex border-b">Languages Spoken</div>
      <div className="mb-4">
          <div className="flex mb-2">
            <input
              type="text"
              id="languages"
              name="languages"
              value={newLanguage}
              placeholder='Add a language'
              onChange={(e) => setNewLanguage(e.target.value)}
              className="mr-4 block w-3/4 px-3 py-2 text-gray-700 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            />
            <button
              onClick={handleAddLanguage}
              className="w-1/4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
              Add
            </button>
          </div>
          <div className="flex flex-wrap">
          {(formData.languages || []).map((language, index) => (
              <div key={index} className="flex items-center mb-2 mr-2 bg-gray-200 px-3 py-1 rounded-full">
                {language}
                <button onClick={() => handleRemoveLanguage(index)} className="ml-2 text-red-500 font-bold">
                  X
                </button>
              </div>
            ))}
          </div>
        </div>
    </div>
    <div className='w-full max-w-sm bg-white p-4 shadow-md rounded-lg mx-auto mt-4'>
      <div className="text-lg font-bold mb-6 flex border-b">About Me</div>
      <div className="mt-2">
        <label htmlFor="bio" className="text-sm">Bio</label>
        <textarea id="bio" name="bio" value={formData.bio || ''} maxLength="255" onChange={handleChange} className="block w-full p-2 border border-gray-300 rounded mt-1" rows="4" />
        <p style={{ fontSize: 'small', textAlign: 'right', color: 'rgba(0, 0, 0, 0.6)' }}>
          {formData.bio ? formData.bio.length : 0}/255 Characters
        </p>
      </div>
      <div className="mt-2">
        <label htmlFor="availability" className="text-sm">Availability</label>
        <textarea id="availability" name="availability" value={formData.availability || ''} maxLength="255" onChange={handleChange} className="block w-full p-2 border border-gray-300 rounded mt-1" rows="4" />
        <p style={{ fontSize: 'small', textAlign: 'right', color: 'rgba(0, 0, 0, 0.6)' }}>
          {formData.availability ? formData.availability.length : 0}/255 Characters
        </p>
      </div>

      <button
        type="submit"
        className={`w-full mt-6 py-2 px-4 mb-4 rounded text-white ${loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600'}`}
        disabled={loading || uploading} // Disable button if loading or uploading
      >
        {loading ? 'Saving...' : 'Save'}
      </button>
    </div>
    </>
  );
};
export default EditUserInfo;