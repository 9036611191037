import { useEffect, useState } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { db } from '../firebase'; // Adjust the path according to your file structure
import { Switch } from '@headlessui/react';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { TrashIcon, PencilSquareIcon } from '@heroicons/react/24/outline'
const MyMenuComponent = () => {
  const [menuItems, setMenuItems] = useState([]);
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const userId = user.uid; // Get the current authenticated user's ID
        const fetchMenuItems = async () => {
          const q = query(collection(db, 'menuitems'), where('user_id', '==', userId));
          const querySnapshot = await getDocs(q);
          const items = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
          setMenuItems(items);
        };
        fetchMenuItems();
      }
    });
    return () => unsubscribe(); // Clean up the listener on unmount
  }, []);
  return (
    <div className="grid grid-cols-1 gap-4">
      {menuItems.map((item, index) => (
        <Stack key={index} direction="column" spacing={2} alignItems="center" sx={{ border: '1px solid #ddd', padding: '16px', borderRadius: '8px' }} >
          <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
            <div className='flex items-center gap-2'>
            <Avatar alt={item.name} src={item.photo} />
            <div className='text-lg font-semibold'>{item.name}</div>
            </div>
            <div className='flex gap-4'>
              <PencilSquareIcon className="h-6 w-6 text-blue-500" />
              <TrashIcon className="h-6 w-6 text-red-500" />
            </div>
          </Stack>
          <div className='w-full text-sm'><span>Description:</span><p className='pl-4 pr-4'>{item.description}</p></div> 
          <div className='w-full text-sm'><span>Price: $</span>{parseFloat(item.price).toFixed(2)}</div>
          <div className='w-full text-sm items-center'><span>Available: </span><p>
          <Switch
            checked={item.available}
            onChange={(e) => {
              alert(item.id)
              
            }}
            className={`${item.available ? 'bg-blue-600' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full ml-2`}
          >
            <span className={`${item.available ? 'translate-x-6' : 'translate-x-1'} inline-block h-4 w-4 transform rounded-full bg-white transition`} />
          </Switch>
          </p>
        </div>
          {/* Available, Edit, Delete */}
        </Stack>
      ))}
    </div>
  );
};
export default MyMenuComponent;
