import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { functions } from "../firebase.js";
import { httpsCallable } from "firebase/functions";
const EditVehiclePage = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);  
  const [newColor, setNewColor] = useState("");
  const [newPlate, setNewPlate] = useState("");
  const [resultMessage, setResultMessage] = useState("");
  const [amenities, setAmenities] = useState([]);
  const [newAmenity, setNewAmenity] = useState("");
  const [newRule, setNewRule] = useState("");
  const [newImage, setNewImage] = useState("");
  const [previewImageUrl, setPreviewImageUrl] = useState("");
  const [rules, setRules] = useState([]);
  const [newRate, setNewRate] = useState({});
  const { vehicle } = location.state || {};
  const handleUpdateColor = async () => {
    setLoading(true);
    try {
      const updateColor = httpsCallable(functions, "updateVehicleColor");
      const result = await updateColor({ vehicleId: vehicle.id, color: newColor }); 
      setResultMessage(result.data.message);
      setLoading(false);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  const handleUpdateRates = async () => {
    setLoading(true);
    try {
      const updateRates = httpsCallable(functions, "updateVehicleRates");
      const result = await updateRates({ vehicleId: vehicle.id, rates: newRate });
      setResultMessage(result.data.message);
      setLoading(false);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }
  const handleUpdatePlate = async () => {
    setLoading(true);
    try {
      const updatePlate = httpsCallable(functions, "updateVehiclePlate");
      const result = await updatePlate({ vehicleId: vehicle.id, plate: newPlate });
      setResultMessage(result.data.message);
      setLoading(false);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  const handleAddAmenity = async () => {
    if (newAmenity.trim()) {
      setLoading(true);
      try {
        const addAmenity = httpsCallable(functions, "addVehicleAmenity");
        const result = await addAmenity({ vehicleId: vehicle.id, amenity: newAmenity });
        setResultMessage(result.data.message);
        if (result.data.success) {
          setAmenities([...amenities, newAmenity]);
          setNewAmenity("");
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
  };
  const handleRemoveAmenity = async (index) => {
    setLoading(true);
    try {
      const removeAmenity = httpsCallable(functions, "removeVehicleAmenity");
      const amenityToRemove = amenities[index];
      const result = await removeAmenity({ vehicleId: vehicle.id, amenity: amenityToRemove });
      setResultMessage(result.data.message);
      if (result.data.success) {
        setAmenities(amenities.filter((_, i) => i !== index));
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  const handleAddRule = async () => {
    if (newRule.trim()) {
      setLoading(true);
      try {
        const addRule = httpsCallable(functions, "addVehicleRule");
        const result = await addRule({ vehicleId: vehicle.id, rule: newRule });
        setResultMessage(result.data.message);
        if (result.data.success) {
          setRules([...rules, newRule]);
          setNewRule("");
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
  };
  const handleRemoveRule = async (index) => {
    setLoading(true);
    try {
      const removeRule = httpsCallable(functions, "removeVehicleRule");
      const ruleToRemove = rules[index];
      const result = await removeRule({ vehicleId: vehicle.id, rule: ruleToRemove });
      setResultMessage(result.data.message);
      if (result.data.success) {
        setRules(rules.filter((_, i) => i !== index));
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  const handleFileChange = async (e) => {
    setLoading(true);
    const file = e.target.files[0];
    if (file) {
      const image = new Image();
      const reader = new FileReader();
      reader.onload = (event) => {
        image.src = event.target.result;
        image.onload = async () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          // Set canvas dimensions to match the image
          canvas.width = image.width;
          canvas.height = image.height;
          // Draw the image onto the canvas
          ctx.drawImage(image, 0, 0);
          // Convert the canvas to WebP and then to base64
          const dataUrl = canvas.toDataURL('image/webp');
          const base64Image = dataUrl.split(',')[1]; // Extract the base64 part
          try {
            const updateColor = httpsCallable(functions, "updateVehiclePhoto");
            const result = await updateColor({ vehicleId: vehicle.id, photo: base64Image });
            setResultMessage(result.data.message);
          } catch (err) {
            console.error(err);
          } finally {
            setLoading(false);
          }
          setPreviewImageUrl(dataUrl);
        };
      };
      reader.readAsDataURL(file);
    } else {
      setLoading(false);
    }
  };
  const handleRemoveImage = async () => {
    setLoading(true);
    try {
      const removeVehiclePhoto = httpsCallable(functions, "removeVehiclePhoto");
      const result = await removeVehiclePhoto({ vehicleId: vehicle.id });
      setResultMessage(result.data.message);
      if (result.data.success) {
        setPreviewImageUrl(null);
        vehicle.mainphoto = null;
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="mainBox">
      <h1 className="text-xl font-semibold mb-4">Edit Vehicle</h1>
      <div className="whiteBox">
        <div className='mb-4 flex justify-center flex-col items-center'>
          {previewImageUrl || vehicle.mainphoto ? (
            <>
          <img className="rounded-full h-48 w-48" src={previewImageUrl || vehicle.mainphoto} id='preview' alt=''></img>
          <div className="flex justify-between mt-4 w-full">
            <button className="bg-red-500 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded-full mr-2" onClick={handleRemoveImage} >
              Remove Image
            </button>
            <button 
              className="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-full" 
              onClick={() => document.getElementById('fileInput').click()}
            >
              Change Image
            </button>
            <input
              type="file"
              id="fileInput"
              className="hidden"
              onChange={handleFileChange} // Replace with your function to handle the file
            />
          </div>
          </>
          ) : (
            <>
            <button 
              className="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-full" 
              onClick={() => document.getElementById('fileInput').click()}
            >
              Add Image
            </button>
            <input
              type="file"
              id="fileInput"
              className="hidden"
              onChange={handleFileChange} // Replace with your function to handle the file
            />
            </>
          )}
        </div>
        <div className="text-center mb-4">{vehicle.year} {vehicle.make} {vehicle.model}</div>
        <div className="text-center mb-4 text-blue-500">{resultMessage}</div>
        {/* Color Input */}
        <div className="mb-4">
          <label htmlFor="color" className="block text-gray-700 font-bold mb-2 text-sm">
            Color
          </label>
          <div className='flex justify-between'>
            <input
              type="text"
              id="color"
              name="color"
              value={vehicle.color}
              disabled={loading}
              onChange={(e) => setNewColor(e.target.value)}
              className="mr-4 block w-3/4 px-3 py-2 text-gray-700 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            />
            <button
              type="submit"
              disabled={loading}
              onClick={handleUpdateColor}
              className="w-1/4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
              <span> {loading ? 'Saving...' : 'Save'} </span>
            </button>
          </div>
        </div>
        {/* License Plate Input */}
        <div className="mb-4">
          <label htmlFor="licensePlate" className="block text-gray-700 font-bold mb-2 text-sm">
            License Plate
          </label>
          <div className='flex justify-between'>
            <input
              type="text"
              id="licensePlate"
              name="licensePlate"
              value={vehicle.plate}
              disabled={loading}
              onChange={(e) => setNewPlate(e.target.value)}
              className="mr-4 block w-3/4 px-3 py-2 text-gray-700 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            />
            <button
              type="submit"
              disabled={loading}
              onClick={handleUpdatePlate}
              className="w-1/4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
              <span> {loading ? 'Saving...' : 'Save'} </span>
            </button>
          </div>
        </div>
        {/* Amenities Section */}
        <div className="mb-4">
          <label htmlFor="amenities" className="block text-gray-700 font-bold mb-2 text-sm">
            Amenities
          </label>
          <div className="flex mb-2">
            <input
              type="text"
              id="amenities"
              name="amenities"
              value={newAmenity}
              onChange={(e) => setNewAmenity(e.target.value)}
              className="mr-4 block w-3/4 px-3 py-2 text-gray-700 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            />
            <button
              onClick={handleAddAmenity}
              className="w-1/4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
              Add
            </button>
          </div>
          <div className="flex flex-wrap">
            {amenities.map((amenity, index) => (
              <div key={index} className="flex items-center mb-2 mr-2 bg-gray-200 px-3 py-1 rounded-full">
                {amenity}
                <button
                  onClick={() => handleRemoveAmenity(index)}
                  className="ml-2 text-red-500 font-bold">
                  X
                </button>
              </div>
            ))}
          </div>
        </div>
        {/* Rules Section */}
        <div className="mb-4">
          <label htmlFor="rules" className="block text-gray-700 font-bold mb-2 text-sm">
            Rules
          </label>
          <div className="flex mb-2">
            <input
              type="text"
              id="rules"
              name="rules"
              value={newRule}
              onChange={(e) => setNewRule(e.target.value)}
              className="mr-4 block w-3/4 px-3 py-2 text-gray-700 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            />
            <button
              onClick={handleAddRule}
              className="w-1/4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
              Add
            </button>
          </div>
          <ol className="list-decimal pl-5">
            {rules.map((rule, index) => (
              <li key={index} className="flex items-center mb-2">
                {rule}
                <button
                  onClick={() => handleRemoveRule(index)}
                  className="ml-2 text-red-500 font-bold">
                  X
                </button>
              </li>
            ))}
          </ol>
        </div>
        {/* Rates Section */}
        <div className="mb-4">
          <label htmlFor="rates" className="block text-gray-700 font-bold mb-2 text-sm">
            Rates
          </label>
          <div className="flex mb-2">
          <textarea
              id="rates"
              name="rates"
              value={vehicle.rates}
              onChange={(e) => setNewRate(e.target.value)}
              className="mr-4 block w-3/4 px-3 py-2 text-gray-700 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            />
            <button
              onClick={handleUpdateRates}
              className="w-1/4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EditVehiclePage;
