import React, { useState, useEffect, useRef } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, doc, onSnapshot, query, orderBy, getDoc } from 'firebase/firestore';
const MessageComponent = ({ recipientId, conversationId }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [recipientName, setRecipientName] = useState('');
  const auth = getAuth();
  const endOfMessagesRef = useRef(null);
  const currentUserId = auth.currentUser?.uid;
  const firestore = getFirestore();
  // Function to send a new message
  const handleSendMessage = async () => {
    if (newMessage.trim()) {
      const functions = getFunctions();
      const sendMessage = httpsCallable(functions, 'sendMessage');
      try {
        await sendMessage({
          recipientId,
          messageText: newMessage,
          conversationId
        });
        setNewMessage('');
      } catch (error) {
        console.error('Error sending message:', error);
      }
    }
  };
  // Fetch the recipient's username
  useEffect(() => {
    if (!recipientId) {
      console.error('Recipient ID is missing.');
      return; // Ensure recipientId is present
    }
  
    console.log('Fetching username for recipientId:', recipientId);
  
    const fetchRecipientName = async () => {
      try {
        const recipientDoc = await getDoc(doc(firestore, 'profiles', recipientId));
        if (recipientDoc.exists()) {
          console.log('Recipient profile data:', recipientDoc.data());
          setRecipientName(recipientDoc.data().username);
        } else {
          console.error('Recipient profile does not exist for ID:', recipientId);
          setRecipientName('Unknown User');
        }
      } catch (error) {
        console.error('Error fetching recipient name:', error);
        setRecipientName('Unknown User');
      }
    };
  
    fetchRecipientName();
  }, [recipientId, firestore]);
  
  // Set up real-time listener for messages
  useEffect(() => {
    if (!conversationId) return; // Ensure conversationId is present
    const messagesRef = collection(firestore, 'conversations', conversationId, 'messages');
    const q = query(messagesRef, orderBy('timestamp', 'asc'));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const fetchedMessages = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));
      setMessages(fetchedMessages);
      setLoading(false);
    }, (error) => {
      console.error('Error fetching messages:', error);
      setLoading(false);
    });
    return () => unsubscribe();
  }, [conversationId, firestore]);
  // Auto-scroll to the bottom when messages change
  useEffect(() => {
    if (endOfMessagesRef.current) {
      const timer = setTimeout(() => {
        endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [messages]);
  return (
    <div className="p-4 w-full flex flex-col bg-white border-0 border-gray-300 rounded-md shadow">
      {/* Display the recipient's username */}
      <div className="text-lg font-semibold border-b border-gray-300 mb-0">Name: {recipientName}</div>
      {/* Messages Container */}
      <div className="overflow-y-auto space-y-4 mb-0 mt-0 p-2 w-full flex flex-grow flex-col border-l border-r border-gray-300 h-[400px]">
        {loading ? (
          <div>Loading...</div>
        ) : (
          <>
            {messages.map((message) => (
              <div
                key={message.id}
                className={`p-2 rounded-md w-fit max-w-xs ${
                  message.senderId === auth.currentUser?.uid ? 'self-end bg-blue-500 text-white' : 'self-start bg-gray-200'
                }`}
              >
                {message.content}
              </div>
            ))}
            <div ref={endOfMessagesRef} />
          </>
        )}
      </div>
      {/* Input for New Message */}
      <div className="flex items-center mt-auto border-t border-gray-300 pt-2">
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          className="flex-grow p-2 border border-gray-300 rounded-md mr-2"
          placeholder="Type a message..."
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleSendMessage();
            }
          }}
        />
        <button
          onClick={handleSendMessage}
          className="bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600"
        >
          Send
        </button>
      </div>
    </div>
  );
};
export default MessageComponent;
