import React, { useState } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import InboxComponent from '../components/InboxComponent';
import MessageComponent from '../components/MessageComponent';

const MessagesPage = () => {
  const [selectedConversationId, setSelectedConversationId] = useState(null);
  const [recipientId, setRecipientId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const functions = getFunctions();

  const sendMessage = async (messageText) => {
    const sendMessageFunction = httpsCallable(functions, 'sendMessage');
    try {
      await sendMessageFunction({
        conversationId: selectedConversationId,
        messageText
      });
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleSelectConversation = (conversationId, recipientId) => {
    setSelectedConversationId(conversationId);
    setRecipientId(recipientId);
  };

  const handleBackToInbox = () => {
    setSelectedConversationId(null);
  };

  return (
    <div className="flex flex-col h-screen">
      <div className="flex flex-1">
        <div
          className={`${
            selectedConversationId ? 'hidden' : 'block'
          } md:block w-full md:w-1/3 border-r border-gray-300 overflow-y-auto`}
        >
          {/* InboxComponent handles fetching conversations in real-time */}
          <InboxComponent
            selectConversation={handleSelectConversation}
          />
        </div>
        <div
          className={`${
            selectedConversationId ? 'block' : 'hidden'
          } md:block w-full h-full md:w-2/3 p-4 flex flex-col max-h-[400px]`}
        >
          {selectedConversationId ? (
            <>
              <button
                onClick={handleBackToInbox}
                className="mb-4 px-4 py-2 bg-blue-500 text-white rounded"
              >
                Back to Inbox
              </button>
              <div className="flex flex-col">
                <MessageComponent
                  recipientId={recipientId}
                  conversationId={selectedConversationId}
                />
              </div>
            </>
          ) : (
            <div>Select a conversation to see messages</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MessagesPage;
