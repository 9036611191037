import { useEffect, useState } from 'react';
import AddMenuItemModal from '../../modals/AddMenuItemModal';
import { TextField, TextAreaField } from '../../components/Fields';
import { Switch } from '@headlessui/react';
import { Button } from '../../components/Button';
import { functions } from '../../firebase.js';
import { httpsCallable } from 'firebase/functions';
import MyMenuComponent from '../../components/MyMenuComponent.js';
const MyMenuPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const [photo, setPhoto] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [available, setAvailable] = useState(true);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      const addMenuItem = httpsCallable(functions, 'addMenuItem');
      // Convert price to an integer
      const priceAsInteger = parseInt(price, 10);
      const result = await addMenuItem({ name, description, price: priceAsInteger, photo, available });
      console.log(result);
    } catch (err) {
      setError(err.message);
    } finally {
      setName('');
      setDescription('');
      setPrice('');
      setPhoto(null);
      setPreviewUrl(null);
      setAvailable(true);
      closeModal();
      setLoading(false);
    }
  };
  const handlePhotoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);
          // Convert the image to WebP format and create a Blob
          canvas.toBlob(
            (blob) => {
              if (blob) {
                // Convert the Blob to base64
                const fileReader = new FileReader();
                fileReader.onloadend = () => {
                  const base64data = fileReader.result.split(',')[1]; // Extract base64 content without the prefix
                  setPhoto(base64data); // Set the base64 string in state
                  setPreviewUrl(URL.createObjectURL(blob)); // Set the Blob URL for image preview
                };
                fileReader.readAsDataURL(blob); // Convert Blob to base64
              }
            },
            'image/webp',
            0.8 // Adjust quality if needed
          );
        };
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex justify-between items-center w-full max-w-[1200px] px-4 py-6">
        <h1>My Menu</h1>
        <button className="bg-blue-500 text-white px-4 py-2 rounded-md mt-1" onClick={openModal}>
          Add Menu Item
        </button>
      </div>
      <div className='w-full bg-white p-4 shadow-md rounded-lg mx-auto'>
        <MyMenuComponent />
      </div>
      <AddMenuItemModal isOpen={isModalOpen} onClose={closeModal}>
        <div className='w-full border-b mb-4'>Add Menu Item</div>
        {/* Photo Upload Section */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">Upload Photo</label>
          <input type="file" accept="image/*" onChange={(e) => handlePhotoUpload(e)} className="mb-2" />
          {previewUrl && (
            <img src={previewUrl} alt="Preview" className="w-full h-40 object-contain rounded mb-4" />
          )}
        </div>
        <form onSubmit={handleSubmit}> {/* Wrap in form */}
          <div className="mb-2">
            <TextField label="Item Name" name="name" type="text" autoComplete="name" value={name} onChange={(e) => setName(e.target.value)} required />
          </div>
          <div className="mb-2">
            <TextAreaField label="Description" name="description" type="text" autoComplete="description" value={description} onChange={(e) => setDescription(e.target.value)} required />
          </div>
          <div className="mb-2">
            <TextField label="Price" type="number" icon="$" placeholder="Enter amount" value={price} onChange={(e) => setPrice(e.target.value)} required />
          </div>
          <div className="mb-2 flex items-center justify-center mt-4">
            <span>Available</span>
            <Switch
              checked={available}
              onChange={setAvailable}
              className={`${available ? 'bg-blue-600' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full ml-8`}
            >
              <span className={`${available ? 'translate-x-6' : 'translate-x-1'} inline-block h-4 w-4 transform rounded-full bg-white transition`} />
            </Switch>
          </div>
          <div className="mb-2 mt-6">
            <Button type="submit" variant="solid" color="blue" className="w-full" disabled={loading}>
              <span> {loading ? 'Adding...' : 'Add Item'} </span>
            </Button>
          </div>
        </form>
      </AddMenuItemModal>
    </div>
  );
};
export default MyMenuPage;
