import React from 'react';
import { functions } from "../firebase.js";
import { httpsCallable } from 'firebase/functions';



// Get Firebase functions instance
//const functions = getFunctions();

const TestPage = () => {
  const handleSendTestEmail = async () => {
    const sendTestEmail = httpsCallable(functions, 'sendTestEmail');

    try {
      const result = await sendTestEmail({ email: 'the.zack.lavigne@gmail.com' }); // Replace with the recipient's email
      console.log('Email sent successfully:', result.data.message);
    } catch (error) {
      console.error('Error sending email:', error.message);
    }
  };

  return (
    <div>
      <h1>Test Page</h1>
      <button onClick={handleSendTestEmail}>Send Test Email</button>
    </div>
  );
}

export default TestPage;
